/* Styles for the RedirectingToGoogle component */

.rtg-container {
  /* Base styles */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;



  .redirect-content {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
  }

  h3 {
    color: #444;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 28px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #444;
  }

  .loader {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 5px solid rgb(220, 220, 220);
    border-radius: 50%;
    border-top-color: #4285F4;
    animation: spin 1s ease-in-out infinite;
    margin: 10px 0;
  }

  .google-logo {
    display: flex;
    justify-content: center;
    margin: 15px 0 10px 0;
  }

  .google-logo span {
    font-size: 28px;
    font-weight: bold;
  }

  .g-blue {
    color: #4285F4;
  }

  .g-red {
    color: #EA4335;
  }

  .g-yellow {
    color: #FBBC05;
  }

  .g-green {
    color: #34A853;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}