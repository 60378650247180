/* Styles for the VerifyGoogleLogin component */

.vgl-container {
  /* Base styles */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;

  .verify-content {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
  }

  h3 {
    color: #444;
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 28px;
    text-align: center;
  }

  .current-user-info {
    margin: 20px 0;
    text-align: left;
  }

  .current-user-info p {
    font-size: 18px;
    margin-bottom: 10px;
    color: #444;
  }

  .user-profile {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 10px 0 25px 0;
    padding: 0;
    box-shadow: none;
  }

  .user-name {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;
    color: #333;
  }

  .user-email {
    color: #666;
    font-size: 16px;
  }

  .confirmation-message {
    margin: 20px 0 25px 0;
    text-align: left;
  }

  .confirmation-message p {
    font-size: 18px;
    line-height: 1.5;
    color: #444;
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 25px;
  }

  .continue-button {
    background-color: #4285F4;
    border-color: #4285F4;
    padding: 12px 0;
    font-size: 16px;
    transition: all 0.2s ease;
    border-radius: 4px;
    font-weight: 500;
  }

  .continue-button:hover,
  .continue-button:focus,
  .continue-button:active {
    background-color: #3367d6 !important;
    border-color: #3367d6 !important;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .signout-button {
    background-color: #EA4335;
    border-color: #EA4335;
    padding: 12px 0;
    font-size: 16px;
    transition: all 0.2s ease;
    border-radius: 4px;
    font-weight: 500;
  }

  .signout-button:hover,
  .signout-button:focus,
  .signout-button:active {
    background-color: #d33426 !important;
    border-color: #d33426 !important;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}